<template>
  <section>
    <b-card-title class="my-1">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <feather-icon
            icon="UserIcon"
            size="24"
            class="mx-50"
          />
          <span>{{ $t('agency.agencyDetail') }}</span>
        </div>
        <!-- Right Button Edit -->
        <div>
          <template v-if="readonly">
            <b-button
              variant="info"
              :disabled="!canAccess('agency.updateAgency')"
              class="ml-1"
              @click="handleEdit"
            >
              <span class="">{{ $t('edit') }}</span>
            </b-button>
          </template>
          <b-button
            v-else
            variant="outline-info"
            :disabled="!canAccess('agency.updateAgency')"
            class="ml-1"
            @click="handleCancelEdit"
          >
            <span class="">{{ $t('cancelEdit') }}</span>
          </b-button>
        </div>
      </div>
    </b-card-title>

    <validation-observer
      #default="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form @submit.prevent="handleSubmit(updatedAgencyHandle)">
        <!-- SECTION Card agency basic info -->
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mt-1"
        >
          <template #header>
            <h6 class="m-0">
              {{ $t('agency.agencyInfo') }}
            </h6>
          </template>
          <b-card-body class="p-0">
            <b-media
              no-body
              class="mt-1"
            >
              <!-- ANCHOR company avatar -->
              <b-media-aside
                v-if="readonly"
                class="mr-0 position-relative"
              >
                <b-avatar
                  size="70"
                  :src="agencyToEdit.avatar"
                  :variant="`light-success`"
                  height="70"
                />
              </b-media-aside>
              <b-media-aside
                v-else
                class="mr-0 position-relative"
              >
                <b-link @click="$refs.refAgencyAvatar.$el.click()">
                  <b-avatar
                    size="70"
                    :src="agencyToEdit.avatar"
                    :variant="`light-success`"
                    height="70"
                  />
                </b-link>
                <b-button
                  variant="light"
                  size="sm"
                  pill
                  class="position-absolute"
                  style="padding: 4px; top: -10px; right: -10px;"
                  @click="clearAgencyAvatar()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-form-file
                  ref="refAgencyAvatar"
                  v-model="agencyAvatar"
                  accept=".jpg, .png"
                  :hidden="true"
                  plain
                  @input="inputImageFormAgency"
                />
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <b-card-text class="text-heading-5 mb-25">
                  {{ agencyToEdit.agencyName }}
                </b-card-text>
                <b-card-text>{{ agencyToEdit.agencyCode.toUpperCase() }}</b-card-text>
              </b-media-body>
            </b-media>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.bookerId')"
                  rules="bookerId|min:5"
                >
                  <b-form-group
                    :label="$t('agency.bookerId')"
                    label-for="bookerId"
                  >
                    <b-form-input
                      id="bookerId"
                      v-model="agencyToEdit.bookerId"
                      :name="$t('agency.bookerId')"
                      :disabled="!canEditBookerId || readonly"
                      :state="getValidationState(validationContext) === false ? false : null"
                      :formatter="trimAllInputAndUpper"
                      :placeholder="$t('agency.bookerId')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- ANCHOR Agency Owner Name -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.labelOwner')"
                  rules="required|max:30"
                >
                  <b-form-group label-for="agencyOwner">
                    <template #label>
                      {{ $t('agency.labelOwner') }}
                      <span
                        v-if="isEditActive"
                        class="text-danger"
                      >(*)</span>
                    </template>
                    <b-form-input
                      id="agencyOwner"
                      v-model="agencyToEdit.agencyOwner.name"
                      :disabled="readonly || !canEdit"
                      :state="getValidationState(validationContext) === false ? false : null"
                      maxlength="30"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('agency.placeholderOwner')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Email Address: email, max 150 -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.labelEmail')"
                  rules="required|email"
                  mode="eager"
                >
                  <!-- Bỏ unique: |isUnique:agencies,email,${agenciesData.emailAddress} -->
                  <b-form-group label-for="emailAddress">
                    <template #label>
                      {{ $t('agency.labelEmail') }}
                      <span
                        v-if="isEditActive"
                        class="text-danger"
                      >(*)</span>
                    </template>
                    <b-form-input
                      id="emailAddress"
                      v-model="agencyToEdit.emailAddress"
                      :disabled="readonly || !canEdit"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                      debounce="300"
                      :placeholder="$t('agency.placeholderEmail')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Agency Owner Phone Number -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.labelOwnerPhone')"
                  rules="required"
                >
                  <!-- |phoneNumber -->
                  <b-form-group label-for="ownerPhone">
                    <template #label>
                      {{ $t('agency.labelOwnerPhone') }}
                      <span
                        v-if="isEditActive"
                        class="text-danger"
                      >(*)</span>
                    </template>
                    <b-form-input
                      id="ownerPhone"
                      v-model="agencyToEdit.agencyOwner.phoneNumber"
                      v-remove-non-numeric-chars.allNumber
                      :disabled="readonly || !canEdit"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('agency.placeholderOwnerPhone')"
                    />
                    <!-- maxlength="10" -->

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Date Of Birth -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.birthday')"
                >
                  <b-form-group label-for="birthday">
                    <template #label>
                      {{ $t('agency.birthday') }}
                      <!-- <span class="text-danger">(*)</span> -->
                    </template>
                    <b-form-datepicker
                      v-model="agencyToEdit.birthDayOwner"
                      name="birthday"
                      class="form-control"
                      :placeholder="$t('customer.placeholderDoB')"
                      :disabled="readonly || !canEdit"
                      :state="getValidationState(validationContext) === false ? false : null"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      :min="new Date(1920, 0, 1)"
                      :max="new Date()"
                      show-decade-nav
                      hide-header
                      locale="vi"
                      no-flip
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Identity Card Number -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.labelIdentityCardNum')"
                  rules="max:15"
                >
                  <b-form-group label-for="identityCardNumber">
                    <template #label>
                      {{ $t('agency.labelIdentityCardNum') }}
                      <!-- <span class="text-danger">(*)</span> -->
                    </template>
                    <b-form-input
                      id="identityCardNumber"
                      v-model="agencyToEdit.identityCardNumber"
                      v-remove-non-numeric-chars.allNumber
                      :disabled="readonly || !canEdit"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('agency.placeholderIdentityCardNum')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Identity Card Name -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.labelIdentityCardName')"
                  rules=""
                >
                  <b-form-group label-for="identityCardName">
                    <template #label>
                      {{ $t('agency.labelIdentityCardName') }}
                      <!-- <span class="text-danger">(*)</span> -->
                    </template>
                    <b-form-input
                      id="identityCardName"
                      v-model="agencyToEdit.identityCardName"
                      :disabled="readonly || !canEdit"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                      maxlength="255"
                      :placeholder="$t('agency.placeholderIdentityCardName')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Place Of Issue -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.labelPlaceOfIssue')"
                  rules=""
                >
                  <b-form-group label-for="placeOfIssue">
                    <template #label>
                      {{ $t('agency.labelPlaceOfIssue') }}
                      <!-- <span class="text-danger">(*)</span> -->
                    </template>
                    <b-form-input
                      id="placeOfIssue"
                      v-model="agencyToEdit.placeOfIssue"
                      :disabled="readonly || !canEdit"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                      maxlength="255"
                      :placeholder="$t('agency.placeholderPlaceOfIssue')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <b-row>
                  <!-- ANCHOR Identity card front image -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      ref="refProviderIDFrontImageFile"
                      :name="$t('agency.uploadFrontId')"
                    >
                      <b-form-group
                        class="font-weight-bold"
                        label-for="frontIdFrontImage"
                      >
                        <template #label>
                          {{ $t('agency.uploadFrontId') }}
                          <!-- <span class="text-danger">(*)</span> -->
                        </template>
                        <b-form-file
                          id="frontIdFrontImage"
                          ref="refInputFrontIDPhoto"
                          v-model="agencyFile.idFrontImage"
                          :disabled="readonly || !canEdit"
                          accept=".jpg, .png"
                          plain
                          placeholder="Chưa chọn ảnh"
                          :state="
                            getValidationState(validationContext) === false
                              ? false
                              : null
                          "
                          @input="inputImageIDCardFrontFormData"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR Identity card back image -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      ref="refProviderIDBackImageFile"
                      :name="$t('agency.uploadBackId')"
                    >
                      <b-form-group
                        class="font-weight-bold"
                        label-for="frontIdBackImage"
                      >
                        <template #label>
                          {{ $t('agency.uploadBackId') }}
                          <!-- <span class="text-danger">(*)</span> -->
                        </template>
                        <b-form-file
                          id="frontIdBackImage"
                          ref="refInputBackIDPhoto"
                          v-model="agencyFile.idBackImage"
                          accept=".jpg, .png"
                          :disabled="readonly || !canEdit"
                          plain
                          placeholder="Chưa chọn ảnh"
                          :state="
                            getValidationState(validationContext) === false
                              ? false
                              : null
                          "
                          @input="inputImageIDCardBackFormData"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <!-- ANCHOR ID front image -->
              <b-col
                cols="5"
                md="6"
                centered
              >
                <b-img
                  v-if="agencyToEdit.identityCardFrontImage"
                  :src="agencyToEdit.identityCardFrontImage"
                  fluid
                  alt="id card"
                  style="max-height: 200px"
                  class="border shadow-lg mb-1"
                />
              </b-col>

              <!-- ANCHOR Id back image -->
              <b-col
                cols="5"
                md="6"
                centered
              >
                <b-img
                  v-if="agencyToEdit.identityCardBackImage"
                  :src="agencyToEdit.identityCardBackImage"
                  fluid
                  alt="id card"
                  style="max-height: 200px"
                  class="border shadow-lg mb-1"
                />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- !SECTION -->

        <!-- SECTION Agency business information -->
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mt-1"
        >
          <template #header>
            <h6 class="m-0">
              {{ $t('agency.agencyBusinessInfo') }}
            </h6>
          </template>
          <b-card-body class="p-0">
            <b-row class="mt-1">
              <!-- ANCHOR company name -->
              <b-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.agencyCompanyName')"
                  rules="required"
                >
                  <b-form-group label-for="agency-name">
                    <template #label>
                      {{ $t('agency.agencyCompanyName') }}
                      <span
                        v-if="isEditActive"
                        class="text-danger"
                      >(*)</span>
                    </template>
                    <b-form-input
                      id="agency-name"
                      v-model="agencyToEdit.agencyName"
                      :disabled="readonly || !canEdit"
                      :name="$t('agency.agencyCompanyName')"
                      :placeholder="`${$t('agency.enter')} ${$t(
                        'agency.agencyCompanyName',
                      )}`"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Tax Code -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Tax Code"
                  rules=""
                >
                  <b-form-group label-for="tax-code">
                    <template #label>
                      {{ $t('agency.labelTaxCode') }}
                    </template>
                    <b-form-input
                      id="tax-code"
                      v-model="agencyToEdit.taxCode"
                      v-remove-non-numeric-chars.allNumber
                      :disabled="readonly || !canEdit"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('agency.placeholderTaxCode')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Send Monthly Report -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.labelMonthlyReport')"
                  rules=""
                >
                  <b-form-group
                    label-for="enableSendMonthlyReport"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    <template #label>
                      {{ $t('agency.labelMonthlyReport') }}
                      <!-- <span
                        v-if="isEditActive"
                        class="text-danger"
                      >(*)</span> -->
                    </template>
                    <v-select
                      v-if="!readonly"
                      v-model="agencyToEdit.enableSendMonthlyReport"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ]"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="enableSendMonthlyReport"
                      :placeholder="$t('agency.placeholderMonthlyReport')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-input
                      v-else
                      :value="
                        $t(
                          [
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                          ].find(
                            (item) =>
                              item.value ===
                              agencyToEdit.enableSendMonthlyReport,
                          ).label,
                        )
                      "
                      disabled
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Agency Code -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.agencyCode')"
                  rules=""
                >
                  <!-- :rules="`min:${isRoleF1 ? '2' : '4'}|userName|isUnique:agencies,agencyCode`" -->
                  <b-form-group
                    :label="$t('agency.agencyCode')"
                    label-for="agencyCode"
                  >
                    <b-form-input
                      id="agencyCode"
                      :value="agencyToEdit.agencyCode.toUpperCase()"
                      :disabled="readonly || true"
                      :state="getValidationState(validationContext) === false ? false : null"
                      :formatter="upperCaseFormatter"
                      :placeholder="$t('agency.agencyCode')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR company address -->
              <b-col
                cols="12"
                sm="6"
              >
                <b-form-group
                  label-for="agency-address"
                  :label="$t('myAccount.addressCompany')"
                >
                  <b-form-input
                    id="agency-address"
                    v-model="agencyToEdit.agencyAddress"
                    :disabled="readonly|| !canEdit"
                    :name="$t('myAccount.addressCompany')"
                    :placeholder="`${$t('myAccount.enter')} ${$t(
                      'myAccount.addressCompany',
                    )}`"
                  />
                </b-form-group>
              </b-col>

              <!-- ANCHOR Founding Date -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.agencyFoundingDate')"
                >
                  <b-form-group
                    :label="`${$t('agency.agencyFoundingDate')}`"
                    label-for="agencyFoundingDate"
                  >
                    <b-form-datepicker
                      v-model="agencyToEdit.agencyFoundingDate"
                      :name="$t('agency.agencyFoundingDate')"
                      class="form-control"
                      :disabled="readonly || !canEdit"
                      :placeholder="$t('agency.agencyFoundingDate')"
                      :state="getValidationState(validationContext) === false ? false : null"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      :min="new Date(1920, 0, 1)"
                      :max="new Date()"
                      show-decade-nav
                      hide-header
                      locale="vi"
                      no-flip
                    />
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Payment method -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.paymentMethod')"
                  rules="required"
                >
                  <b-form-group
                    class="font-weight-bold"
                    label-for="paymentMethod"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    <template #label>
                      {{ $t('agency.paymentMethod') }}
                      <span
                        v-if="isEditActive"
                        class="text-danger"
                      >(*)</span>
                    </template>
                    <v-select
                      v-model="agencyToEdit.paymentMethod"
                      :disabled="readonly || !canEdit"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="[
                        { label: 'overdraft', value: 'OVERDRAFT' },
                        { label: 'prepay', value: 'PREPAY' },
                      ]"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="paymentMethod"
                      :placeholder="$t('agency.paymentMethod')"
                    >
                      <template #option="data">
                        <span>
                          {{ $t(`agency.${data.label}`) }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $t(`agency.${data.label}`) }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Document contract -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  ref="documentContractFile"
                  :name="`${$t('agency.documentContract')}`"
                >
                  <b-form-group
                    class="font-weight-bold"
                    label-for="documentContractUrlFile"
                  >
                    <template #label>
                      <div class="d-flex-center justify-content-between">
                        {{ $t('agency.documentContract') }}
                        <a
                          :href="agencyToEdit.documentContractUrl"
                          alt="download file"
                          target="_blank"
                        >
                          Tải file
                        </a>
                      </div>
                    </template>
                    <b-form-file
                      id="documentContractUrlFile"
                      ref="refInputDocumentContractUrl"
                      v-model="agencyFile.documentContract"
                      accept=".pdf, .docx, .doc, .txt"
                      :disabled="readonly || !canEdit"
                      plain
                      :state="getValidationState(validationContext) === false ? false : null"
                      @input="inputDocumentContractFormData"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- <b-col class="p-0">
                  <object
                    v-if="agencyToEdit.documentContractUrl"
                    :data="agencyToEdit.documentContractUrl"
                    style="width: 100%; height: 100%"
                  />
                </b-col> -->
              </b-col>

              <!-- ANCHOR limitCredit  -->
              <b-col
                v-if="agencyToEdit.id !== 1000000"
                cols="12"
                sm="6"
              >
                <b-form-group
                  class="font-weight-bold"
                  label-for="agency-limit-credit"
                  :label="`${$t('agency.labelWarningCredit')} / ${$t(
                    'agency.labelLimitCredit',
                  )}`"
                >
                  <div class="d-flex align-items-center">
                    <span class="text-dark font-medium-1 font-weight-bolder ml-75 mr-2">
                      {{ formatCurrency(agencyToEdit.warningCredit) }} /
                      {{ formatCurrency(agencyToEdit.limitCredit) }}
                    </span>
                    <b-button
                      v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                      variant="outline-primary"
                      :disabled="readonly && !['KTT', 'KTV'].includes(meData.type) || !canEdit"
                      class="mr-1 py-50 rounded-lg"
                      @click="openChangeBalanceModal(agencyToEdit)"
                    >
                      {{ $t('adjust') }}
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- !SECTION -->

        <!-- SECTION STATUS -->
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border my-1"
        >
          <!-- ANCHOR header -->
          <template #header>
            <h6 class="m-0">
              {{ $t('agency.labelStatus') }}
            </h6>
          </template>
          <b-card-body class="p-0">
            <b-row class="mt-1">
              <!-- ANCHOR Status -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.labelStatus')"
                  rules="required"
                >
                  <b-form-group
                    label-for="status"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    <template #label>
                      {{ $t('agency.labelStatus') }}
                      <span
                        v-if="isEditActive"
                        class="text-danger"
                      >(*)</span>
                    </template>
                    <v-select
                      v-model="agencyToEdit.status"
                      :options="statusAgencyOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      :disabled="readonly || !canEdit"
                      input-id="status"
                      :placeholder="$t('agency.placeholderStatus')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border my-1"
        >
          <template #header>
            <h6 class="m-0">
              {{ $t('agency.airAccountForAgencyText') }}
            </h6>
          </template>
          <!-- Form Input -->
          <b-card-body class="p-0">
            <h5 class="my-2">
              {{ $t('agency.provideAccount') }}
            </h5>
            <b-row class="mt-1">
              <!-- User name -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.labelAirUsername')"
                  rules=""
                >
                  <b-form-group label-for="airAccountUsername">
                    <template #label>
                      {{ $t('agency.labelAirUsername') }}
                      <!-- <span class="text-danger">(*)</span> -->
                    </template>
                    <!-- FIXME v-model -->
                    <!-- { label: 'Account 1', value: 'account1' },
                    { label: 'Account 2', value: 'account2' },
                    { label: 'Account 3', value: 'account3' }, -->
                    <v-select
                      id="airAccountUsername"
                      v-model="agencyToEdit.airAccount"
                      :options="[
                      ]"
                      :reduce="(val) => val.value"
                      :disabled="readonly || !canEdit"
                      clearable
                      input-id="airAccountUsername"
                      :placeholder="$t('agency.placeholderAirUsername')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Airline -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('agency.labelAirline')"
                  rules=""
                >
                  <b-form-group
                    label-for="airline"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    <template #label>
                      {{ $t('agency.labelAirline') }}
                      <!-- <span
                        v-if="isEditActive"
                        class="text-danger"
                      >(*)</span> -->
                    </template>
                    <v-select
                      v-model="agencyToEdit.airline"
                      :options="airlineOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      :disabled="readonly || !canEdit"
                      input-id="airline"
                      :placeholder="$t('agency.placeholderAirline')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- SECTION Config -->
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border my-1"
        >
          <!-- ANCHOR header -->
          <template #header>
            <h6 class="m-0">
              {{ $t('agency.configs.title') }}
            </h6>
          </template>
          <b-card-body class="p-0 d-flex-center justify-content-start mt-1">
            <!-- ANCHOR - Config chung -->
            <b-row class="w-100 gap-3">
              <b-col
                v-if="isRoleF1 || (isRoleF3 && isF3Retail)"
                cols="12"
                md="auto"
                class="d-flex-center justify-content-start"
              >
                <b-form-checkbox
                  v-model="agencyToEdit.enableDraft"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  inline
                  :disabled="readonly"
                  @click.native.prevent="changeDisableDraft(agencyToEdit.enableDraft)"
                />
                <h6 class="mb-0">
                  {{ agencyToEdit.enableDraft ? $t('agency.configs.enableDraft') : $t('agency.configs.disableDraft') }}
                </h6>
              </b-col>

              <b-col
                v-if="!isRoleF1"
                cols="12"
                md="auto"
                class="d-flex-center justify-content-start"
              >
                <b-form-checkbox
                  v-model="agencyToEdit.autoIssueTicket"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  inline
                  :disabled="readonly"
                />
                <h6 class="mb-0">
                  {{ agencyToEdit.autoIssueTicket ? $t('agency.configs.autoIssueTicket') :
                    $t('agency.configs.disableAutoIssueTicket') }}
                </h6>
              </b-col>

              <!-- ANCHOR - F2 config cho F3 bán lẻ -->
              <b-col
                v-if="isRoleF3 && isF3Retail && agencyToEdit.agencyRetailConfig"
                cols="12"
                md="auto"
                class="d-flex-center justify-content-start"
              >
                <b-form-checkbox
                  v-model="agencyToEdit.agencyRetailConfig.usePhoneCustomer"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  inline
                  :disabled="readonly"
                />
                <h6 class="mb-0">
                  {{ agencyToEdit.agencyRetailConfig.usePhoneCustomer ? $t('agency.configs.usePhoneCustomer') : $t('agency.configs.disableUsePhoneCustomer') }}
                </h6>
              </b-col>

              <b-col
                v-if="isRoleF3 && isF3Retail && agencyToEdit.agencyRetailConfig"
                cols="12"
                md="auto"
                class="d-flex-center justify-content-start"
              >
                <b-form-checkbox
                  v-model="agencyToEdit.agencyRetailConfig.showTicketCondition"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  inline
                  :disabled="readonly"
                />
                <h6 class="mb-0">
                  {{ agencyToEdit.agencyRetailConfig.showTicketCondition ? $t('agency.configs.showTicketCondition') : $t('agency.configs.disableShowTicketCondition') }}
                </h6>
              </b-col>
            </b-row>

            <!-- ANCHOR - F2 config cho F3 -->
            <b-row
              v-if="isRoleF3 && agencyToEdit.parentAgency && agencyToEdit.parentAgency.id !== 1000000"
              class="w-100"
            >
              <b-col
                cols="12"
                md="6"
                class="d-flex-center justify-content-start mb-75 mb-md-0"
              >
                <b-form-checkbox
                  :checked="agencyToEdit.hideFee"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  inline
                  :disabled="readonly || !canEdit || isF3Retail"
                  @click.native.prevent="changeHideFeeConfig(agencyToEdit.id)"
                />
                <h6 class="mb-0">
                  {{ agencyToEdit.hideFee ? $t('agency.configs.hideFee') : $t('agency.configs.showFee') }}
                </h6>
              </b-col>
              <b-col
                v-if="agencyToEdit.parentAgency && agencyToEdit.parentAgency.id !== 1000000 && isRoleF2"
                cols="12"
                md="6"
                class="d-flex-center justify-content-start"
              >
                <b-form-checkbox
                  :checked="agencyToEdit.sendMailAirline"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  inline
                  :disabled="readonly || !canEdit"
                  @click.native.prevent="changeSendMailAirline(agencyToEdit.id)"
                />
                <h6 class="mb-0">
                  {{ agencyToEdit.sendMailAirline ? $t('agency.configs.sendMailAirlineOn') : $t('agency.configs.sendMailAirlineOff') }}
                </h6>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- !SECTION -->

        <!-- ANCHOR - Mạng xã hội -->
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-50 mb-1"
          class="border my-1"
        >
          <template #header>
            <div class="d-flex align-items-center justify-content-between w-100">
              <h6 class="text-airline font-medium-3 m-0">
                Mạng xã hội
              </h6>

              <b-button
                v-if="!readonly"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.v-primary.window
                title="Chỉnh sửa"
                size="sm"
                variant="outline-info"
                class="btn-icon mr-1 py-25 px-50 d-flex-center"
                @click="editSocialNetwork(agencyToEdit)"
              >
                <img src="@icons/edit.svg">
                <span
                  class="font-medium-2 ml-25"
                  style="margin-top: 1px;"
                >
                  Thay đổi mạng xã hội
                </span>
              </b-button>
            </div>
          </template>

          <div v-if="agencyToEdit.socialAccounts && agencyToEdit.socialAccounts.length">
            <div
              v-for="(item, i) in agencyToEdit.socialAccounts"
              :key="i"
              class="mb-50 d-flex align-items-center"
            >
              <span
                class="mr-50 font-weight-bolder"
                style="min-width: 120px"
              >
                {{ item.type }}
              </span>

              <b-link
                class="url-ellipsis"
                :href="item.url"
                target="_blank"
              >
                {{ item.url }}
              </b-link>
            </div>
          </div>

          <div v-else>
            Chưa thêm mạng xã hội
          </div>
        </b-card>

        <!-- ANCHOR - NOTE -->
        <b-card
          v-if="isRoleF1"
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-50 mb-1"
          class="border my-1"
        >
          <template #header>
            <div class="d-flex align-items-center justify-content-between w-100">
              <h6 class="text-airline font-medium-3 m-0">
                {{ $t('reservation.note') }}
              </h6>
              <b-button
                v-if="!readonly"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.v-primary.window
                title="Chỉnh sửa"
                size="sm"
                variant="outline-info"
                class="btn-icon mr-1 py-25 px-50 d-flex-center"
                @click="editNote($event.target)"
              >
                <img src="@icons/edit.svg">
                <span
                  class="font-medium-2 ml-25"
                  style="margin-top: 1px;"
                >
                  Sửa ghi chú
                </span>
              </b-button>
            </div>
          </template>

          <div>
            {{ agencyToEdit.note || $t('reservation.noNote') }}
          </div>
        </b-card>

        <!-- SECTION audit log -->
        <AppCollapse type="border-info card">
          <AppCollapseItem
            title
            class-header="card-header bg-light-info"
            class="border-info"
          >
            <template #header>
              <span class="text-dark font-weight-bolder">
                {{ $t('agency.auditLog') }}
              </span>
            </template>
            <b-table-lite
              bordered
              responsive
              :sticky-header="true"
              show-empty
              :empty-text="$t('noMatchingResult')"
              no-border-collapse
              small
              class="mb-1 rounded"
              thead-class="text-dark font-weight-bolder text-nowrap"
              :items="agencyToEdit.auditLogs"
              :fields="tableColumns"
            >
              <template
                v-for="column in tableColumns"
                #[`head(${column})`]="data"
              >
                <span
                  :key="column"
                  class="text-dark"
                >
                  {{ $t(`agency.${data.column}`) }}
                </span>
              </template>
              <template #cell(no)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(paxUpdated)="{item}">
                <div class="fw-700 text-nowrap">
                  {{ item.modifiedBy?.firstName }} {{ item.modifiedBy?.lastName }}
                </div>
              </template>
              <template #cell(action)="{item}">
                <div>
                  {{ item.action }}
                </div>
              </template>
              <template #cell(updatedData)="{item}">
                <div v-if="item.newValue">
                  <template v-if="['ENABLE_BOOKING_GROUP', 'UPDATE_SEND_EMPLOYEE_MAIL_AIRLINE'].includes(item.action)">
                    {{ $te(item.newValue) ? $t(item.newValue) : item.newValue }}
                  </template>
                  <template v-else-if="!item.action.includes('BALANCE')">
                    <div>
                      <div
                        v-for="(newVal, key) in JSON.parse(item.newValue)"
                        :key="key"
                      >
                        <template v-if="checkTypeValueAuditLog(newVal)">
                          <div
                            v-for="(val, key2) in newVal"
                            :key="key2"
                          >
                            {{ key2 }}: {{ val }}
                          </div>
                        </template>
                        <span v-else>
                          {{ key }}: {{ newVal }}
                        </span>
                      </div>
                    </div>
                  </template>

                  <template v-else-if="Number(item.newValue)">
                    <span>{{ formatCurrency(item.newValue) }}</span>
                  </template>
                </div>
              </template>
              <template #cell(updatedAt)="{item}">
                <div>
                  {{ convertISODateTime(item.createdAt).dateTime }}
                </div>
              </template>
            </b-table-lite>
          </AppCollapseItem>
        </AppCollapse>

        <!-- ANCHOR Action Buttons -->
        <div class="button_actions d-flex mt-1 justify-content-center gap-2">
          <!-- Back button -->
          <b-button
            variant="secondary"
            @click="onCancel"
          >
            {{ $t('back') }}
          </b-button>

          <!-- Save and continue button -->
          <b-button
            v-if="!readonly"
            variant="info"
            :disabled="invalid"
            @click="handleSubmit(() => updatedAgencyHandle(true))"
          >
            {{ $t('save') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>

    <AgenciesChangeBalanceModal
      :agencies-data="agencyToEdit"
      :limit-credit.sync="agencyToEdit.limitCredit"
      :warning-credit.sync="agencyToEdit.warningCredit"
      :is-role-f2="isRoleF2"
      :me-data="meData"
    />

    <NoteModal
      v-if="agencyToEdit"
      :agency-to-edit="agencyToEdit"
      :note.sync="agencyToEdit.note"
      :agencies-data="agenciesData"
    />

    <ModalAddSocialNetwork
      v-if="agencyToEdit"
      :agency-to-edit="agencyToEdit"
      :social-accounts.sync="agencyToEdit.socialAccounts"
      :agencies-data="agenciesData"
    />

    <ModalDisableDraft
      v-if="agencyToEdit"
      :agency-to-edit="agencyToEdit"
      @update-enable-draft="updateEnableDraft"
    />
  </section>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BCardTitle,
  BCardBody,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BFormDatepicker,
  VBTooltip,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BAvatar,
  BCardText,
  BTableLite,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { isEmpty, omit } from 'lodash'

import router from '@/router'
import store from '@/store'
import { airlineOptions, statusAgencyOptions } from '@/constants/selectOptions'
import {
  SENDER_TYPES,
} from '@/constants/topup'

import { getDifference } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText, formatCurrency, convertISODateTime } from '@core/utils/filter'
import { trimInput, upperCaseFormatter, trimAllInputAndUpper } from '@core/comp-functions/forms/formatter-input'
import { useInputImageFormData, useInputFileFormData } from '@core/comp-functions/forms/form-utils'

import ModalDisableDraft from '@agencies/agencies-edit/ModalDisableDraft.vue'
import NoteModal from '@agencies/agencies-edit/NoteModal.vue'
import ModalAddSocialNetwork from '@agencies/agencies-edit/ModalAddSocialNetwork.vue'
import AgenciesChangeBalanceModal from '@agencies/agencies-edit/AgenciesChangeBalanceModal.vue'
import useAgenciesHandle from '@agencies/useAgenciesHandle'

import useToast from '@useToast'
import {
  required, min, max, email, integer, isUnique,
} from '@validations'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BForm,
    BCard,
    BCardBody,
    BCardTitle,
    BFormFile,
    BImg,
    BFormDatepicker,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BCardText,
    BTableLite,

    vSelect,

    ValidationProvider,
    ValidationObserver,

    AgenciesChangeBalanceModal,
    ModalAddSocialNetwork,
    NoteModal,
    ModalDisableDraft,

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    agenciesData: {
      type: Object,
      required: true,
    },
    loadingProp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { toastError } = useToast()
    const agencyFile = ref({
      idFrontImage: null,
      idBackImage: null,
      documentContract: null,
    })

    const refAgencyAvatar = ref(null)
    const agencyAvatar = ref(null)
    const agencyToEdit = ref(JSON.parse(JSON.stringify(props.agenciesData)))
    const resetAgencyToEdit = () => {
      agencyToEdit.value = JSON.parse(JSON.stringify(props.agenciesData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetAgencyToEdit)
    const readonly = ref(true)

    const isEditActive = computed(() => {
      const val = readonly.value === true ? '' : '(*)'
      return val
    })

    const { inputImageFormData: inputImageFormAgency } = useInputImageFormData(refAgencyAvatar, data => {
      agencyToEdit.value.avatar = data.url
    })

    const clearAgencyAvatar = () => {
      agencyToEdit.value.avatar = null
    }

    // for topup
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const meData = computed(() => store.getters['userStore/getMeData'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const senderData = ref({})
    const recipientData = ref({})
    const isEmptyBankAccounts = ref(false)

    const setSenderData = () => {
      senderData.value = {
        id: agencyToEdit.value.id,
        agencyCode: agencyToEdit.value.agencyCode.toUpperCase(),
        type: SENDER_TYPES.AGENCY,
      }
    }

    const setRecipientData = () => {
      recipientData.value = {
        id: agencyData.value.id,
        agencyName: agencyData.value.agencyName,
        bankAccounts: agencyData.value?.bankAccs ?? agencyData.value?.bankAccounts ?? [],
      }

      if (!(agencyData.value?.bankAccs?.length ?? agencyData.value?.bankAccounts?.length)) {
        isEmptyBankAccounts.value = true
      }
    }

    const handleOpenTopupModal = () => {
      setSenderData()
      setRecipientData()
    }
    // END for topup

    const {
      updateAgencies,
      loading,
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,
      updateHideFeeConfig,
      changeSocialNetwork,
    } = useAgenciesHandle()

    const onCancel = () => {
      resetForm()
      router.go(-1)
    }

    function handleEdit() {
      readonly.value = false
    }

    // eslint-disable-next-line no-unused-vars
    async function updatedAgencyHandle(next = false) {
      emit('update:loading-prop', true)
      try {
        const dataToUpdate = omit(getDifference(agencyToEdit.value, props.agenciesData), ['auditLogs', 'topupRecords', 'updatedAt'])
        if (dataToUpdate?.socialAccounts) {
          const payloadSocialAccounts = {
            socialAccounts: dataToUpdate.socialAccounts,
          }
          await changeSocialNetwork(props.agenciesData.id, payloadSocialAccounts)
          delete dataToUpdate.socialAccounts
        }

        if (!isEmpty(dataToUpdate)) {
          await updateAgencies(agencyToEdit.value, dataToUpdate)
        }
      } catch (error) {
        console.error({ error })
      } finally {
        emit('refetchAgency')
        readonly.value = true
        emit('update:loading-prop', false)
      }
    }

    function handleCancelEdit() {
      resetForm()
      readonly.value = true
    }

    // Upload file
    const refInputFrontIDPhoto = ref(null)
    const refInputBackIDPhoto = ref(null)
    const refInputDocumentContractUrl = ref(null)

    const { inputImageFormData: inputImageIDCardFrontFormData } = useInputImageFormData(refInputFrontIDPhoto, data => {
      agencyToEdit.value.identityCardFrontImage = data.url
    })

    const { inputImageFormData: inputImageIDCardBackFormData } = useInputImageFormData(refInputBackIDPhoto, data => {
      agencyToEdit.value.identityCardBackImage = data.url
    })

    const { inputFileFormData: inputDocumentContractFormData } = useInputFileFormData(refInputDocumentContractUrl, data => {
      agencyToEdit.value.documentContractUrl = data.url
    })

    // const onRemoveAvatar = () => {
    //   // eslint-disable-next-line no-param-reassign
    //   agencyToEdit.value.avatar = null
    // }

    // const fetchTotalEmployees = async () => {
    //   try {
    //     const data = await api.employee.fetchAllEmployees()
    //     if (data) {
    //       totalEmployees.value = data.data.total
    //     }
    //   } catch (error) {
    //     console.error(error)
    //   }
    // }
    // fetchTotalEmployees()

    function openChangeBalanceModal(agencyToEdit) {
      if (agencyToEdit.id === meData.value?.agency.id) {
        toastError({
          title: 'messagesList.error',
          content: 'agency.notChangeYouBalance',
        })
      } else {
        this.$bvModal.show('modal-agencies-change-balance')
      }
    }

    function changeHideFeeConfig(id) {
      if (readonly.value) {
        return
      }
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatus'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            updateHideFeeConfig(id)
              .then(res => {
                agencyToEdit.value.hideFee = res.hideFee
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function editNote(event) {
      this.$root.$emit('bv::show::modal', 'agency-change-note-modal', event)
    }

    async function editSocialNetwork(data) {
      await new Promise(resolve => {
        if (!data) return
        resolve()
      })

      this.$bvModal.show('modal-agency-add-social-network')
    }

    async function changeDisableDraft(enableDraft) {
      if (!readonly.value && enableDraft) {
        this.$bvModal.show('modal-disable-draft')
      } else if (!readonly.value && !enableDraft) {
        agencyToEdit.value.enableDraft = !enableDraft
      }
    }

    function updateEnableDraft(value) {
      agencyToEdit.value.enableDraft = value
    }

    const canEditBookerId = computed(() => meData.value.type === 'ADM' && !isRoleF3.value)
    const canEdit = computed(() => isRoleF1.value)

    const isF3Retail = computed(() => props.agenciesData?.agencyCode.endsWith('-RTL'))

    const tableColumns = ref(['no', 'paxUpdated', 'action', 'updatedData', 'updatedAt'])
    const checkTypeValueAuditLog = val => typeof val === 'object'

    return {
      agencyToEdit,
      updatedAgencyHandle,
      onCancel,
      handleEdit,
      handleCancelEdit,
      refFormObserver,
      avatarText,
      readonly,
      meData,
      // Options
      airlineOptions,
      statusAgencyOptions,

      // Validation
      getValidationState,
      required,
      min,
      max,
      email,
      integer,
      isUnique,

      isEditActive,

      // for topup
      senderData,
      recipientData,
      handleOpenTopupModal,

      // Upload file
      refInputFrontIDPhoto,
      refInputBackIDPhoto,
      refInputDocumentContractUrl,
      // previewEl,
      inputImageIDCardFrontFormData,
      inputImageIDCardBackFormData,
      inputDocumentContractFormData,
      // onRemoveAvatar,

      // Filter Agency Manager
      loading,
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,

      openChangeBalanceModal,

      canEditBookerId,
      canEdit,

      trimInput,
      trimAllInputAndUpper,
      upperCaseFormatter,
      agencyFile,
      formatCurrency,

      changeHideFeeConfig,
      editNote,
      editSocialNetwork,
      isRoleF1,
      isRoleF2,
      isRoleF3,
      isEmptyBankAccounts,

      refAgencyAvatar,
      inputImageFormAgency,
      clearAgencyAvatar,
      agencyAvatar,
      isF3Retail,
      tableColumns,
      convertISODateTime,
      changeDisableDraft,
      checkTypeValueAuditLog,
      updateEnableDraft,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.url-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}

.button_actions {
  position: sticky;
  z-index: 22;
  bottom: 10px;
}
</style>
